<template>
    <div class="document-main m-2 mt-3" >
        <b-row>
            <b-col v-if="hasDocuments" cols="12">
                    <b-alert class="mt-2" :show="hasNoDocuments">This level has no documents</b-alert>
                    <Table
                        v-if="!documentsViewMode"
                        :uid="table_uid"
                        :add_new_rows_permission="'create-documents'"
                        :active_id="selected_id"
                        :ref="table_uid"
                        :is_loading="is_loading"
                        :columns="columns"
                        :data="document_tree"
                        prevent_first_row_select
                        :default_values="default_values"
                        :validation="table_validation_rules"
                        :button_methods="button_methods"
                        enforce_structure_checks
                        :default_sort_column="'title'"
                        :delete_requirement="{id: core.id, variable_name: 'owner_user_id'}"
                        :edit_permissions="{manager:'process-manager', user:'process'}"
                        :variable_watchers="variable_watchers"
                        use_level_ids_array
                        has_header_filters
                        hide_action_buttons 
                        no_extra_rows
                        only_editable_columns
                        @updateViewMode="table_view_mode = $event"
                        @selectedRowEvent="selectedRowEvent"
                        @dataCount="documentCountEvent"
                        @saveTable="saveTable"
                        @deleteRows="deleteRow"
                        guideline_key="process_documents"
                    >
                    <template #title>
                            Documents ({{ documentCount }})
                            <span v-show="selected_user_id != null">
                                <span>&nbsp;&nbsp;>&nbsp;&nbsp;</span>
                                <span class="text-success">{{ displaySelectedUserName }}</span>
                            </span>
                        </template>
                        <template #buttons>
                            <div class="btn-group ms-1 me-2 d-flex" size="sm">
                                <span class="bold ms-3 me-1 mt-1" style="padding-top: 2px;">
                                    Child Level Documents:
                                </span>
                                <span class="mt-1" title="Display documents for current level only" v-show="levelOnlyDocuments == false && document_tree.length != 0"><i @click="setLevelOnlyDocuments(1)" class="fa fa-toggle-off fa-lg fa-2x" style="color: #0275d8"></i></span>
                                <span class="mt-1" title="Display documents for current level and below" v-show="levelOnlyDocuments == true && document_tree.length != 0"><i @click="setLevelOnlyDocuments(0)" class="fa fa-toggle-on fa-lg fa-2x" style="color: #0275d8"></i></span>
                                <span class="mt-1" title="Display documents for current level only" v-show="levelOnlyDocuments == false && document_tree.length == 0"><i class="fa fa-toggle-off fa-lg fa-2x" style="color: #D3D3D3"></i></span>
                            </div>

                            
                            <b-button size="sm" variant="primary" @click="addDocumentsModal()" class="me-1" style="display: inline-block">
                                <font-awesome-icon :icon="['fas', 'folder-plus']" />
                            </b-button>

                            <!-- <GuidelineModalButton class="me-1" guideline_key="process_documents"/> -->
                        </template>
                        <template #extra-table-actions>
                            <span class="btn-group">
                                <b-button @click="setDocumentsViewMode(true)" size="sm" :variant="documentsViewMode ? 'primary' : 'outline-primary'"  v-b-tooltip.hover.bottomright title="Tree View">
                                    <font-awesome-icon :icon="['fas', 'sitemap']" />
                                </b-button>
                            </span>
                        </template>
                    </Table>
                    <span>
                        <documentTreeTable style="height: 100%" v-if="documentsViewMode" @viewDocument="triggerViewDocument" @editDocument="triggerEditDocument" />
                    </span>
               
            </b-col>
            <b-col v-if="false" cols="3">
                <b-card v-if="hasDocuments" class="card-hover">
                    <div class="d-flex flex-wrap" style="width: 100%;">
                        <div class="me-2">
                            <h5 class="card-title mb-3 mr-2">Linked Documents</h5>
                        </div>

                        <div class="w-100">
                            <p style="text-decoration: underline;">Parent Documents:</p>
                            <ul v-if="parent_documents.length > 0">
                                <li v-for="(parent, idx) in parent_documents" :key="idx">{{ parent.title }}
                                    ({{ parent.document_type }})</li>
                            </ul>
                            <b-alert v-else :show="true">No Parent Documents</b-alert>
                        </div>
                        <div class="w-100 mt-2">
                            <p style="text-decoration: underline;">Child Documents:</p>
                            <ul v-if="child_documents.length > 0">
                                <li v-for="(child, idx) in child_documents" :key="idx">{{ child.title }}
                                    ({{ child.document_type }})</li>
                            </ul>
                            <b-alert v-else :show="true">No Child Documents</b-alert>
                        </div>
                    </div>
                </b-card>
            </b-col>
            
        </b-row>

        <div v-if="false"><!-- hiding this from andrew for now -->
            <b-row v-if="getDocumentList().length > 0">
                <b-col cols="2" v-for="document in getDocumentList()" :key="document.id">
                    <documentCard :type="document.document_type" :heading="document.title" :status="document.status"
                        :owner="document.owner_name" :id="document.id" />
                    <!-- :id="document.id" -->
                </b-col>
            </b-row>
            <div v-else class="m-1 d-flex">
                <b-alert class="w-100" variant="info" show>
                    <h6 class="text-center m-2"><b-icon class="m-1" icon="exclamation-triangle">
                        </b-icon>Sorry No Documents Match Those Spesifications !</h6>
                </b-alert>
            </div>
        </div>
        <HierarchyModal @updatedLevels="handleRowLevelsUpdate" :current_row="modal_payload" v-if="show_hierarchy_modal" />
        <addDocumentModal  
            v-if="show_add_document_modal"
            :payload="modal_payload"
            @saved="loadDocumentsForLevel()"
        />
        
    </div>
</template>

<script>
import _ from 'lodash';
import addDocumentModal from './modals/addDocumentModal.vue'
import { levelComputed, handbookMethods, handbookComputed, adminComputed, webixTableState, profileComputed, profileMethods } from "@/state/helpers";
import Table from '@/components/widgets/table/table.vue'
import documentTreeTable from './documentTreeTable.vue'
import HierarchyModal from '@/components/widgets/linkingModals/levels.vue'
//import GuidelineModalButton from '../widgets/misc/guidelineModalButton.vue';
export default {
    data: () => ({
        table_uid: 'documentsTable',
        table_view_mode: 'view',
        documentCount: 0,
        //linked docs
        parent_documents: [],
        child_documents: [],
        show_add_document_modal: false,
        selected_id: -1,
        viewChildLevels: 0,
        count: 0,
        test: true,
        is_loading: false,
        show_table: false,

        type_options: {
            'policy': 'Policy',
            'process': 'Process',
            'procedure': 'Procedure',
            'guideline': 'Guideline',
            'template': 'Template',
        },

        show_hierarchy_modal: false,
        modal_payload: null,
    }),
    components: {
        Table,
        HierarchyModal,
        addDocumentModal,
        documentTreeTable,
        //GuidelineModalButton,
    },
    watch: {
        levelOnlyDocuments() {
            this.loadDocumentsForLevel(true);
        },
        current_level_id() {
            this.loadDocumentsForLevel(true);
        },
        selected_user_id() {
            this.loadDocumentsForLevel(true);
        },
        canShowHeaderFilters() {
            this.$nextTick(() => {
                //* check if ref is valid (should be)
                if (this.$refs[this.table_uid]) {
                    //*call method from table component
                    this.$refs[this.table_uid].refreshVisibleColumns()
                }
            })
        },
        notificationChange: {
            handler() {
                if(this.notificationChange) {
                    this.loadDocumentsForLevel()
                }
            }
        },
        selected_level() {
            if(this.selected_level.id) {
                this.manuallySetLastUsedLevelID(this.selected_level.id)
            }
        },
        data_count() {
            console.log('test')
        },
        document_tree() {
            this.$nextTick(() => {
                this.documentCount = this.document_tree.length
            })
        }
    },
    computed: {
        ...levelComputed,
        ...handbookComputed,
        ...adminComputed,
        ...webixTableState,
        ...profileComputed,
        canShowHeaderFilters() {
            console.log(JSON.parse(JSON.stringify(this.table_states)))
            if (this.table_states[this.table_uid] == undefined) {
                return false;
            }
            else {
                return this.table_states[this.table_uid].show_datatable_filters;
            }
        },
        displaySelectedUserName() {
            let name = '';
            if (this.selected_user_object) {
                name = this.selected_user_object.name;
            }
            return name;
        },
        document_list() {
            let docs = this.documents != null ? this.documents : [];
            let filteredDocs = []
            if(this.viewChildLevels == 0) {
                docs.forEach((doc) => {
                    if(doc.level_ids.length > 0) {
                        doc.level_id.forEach(level => {
                            if(level.id == this.selected_level.id) {
                                filteredDocs.push(doc)
                            }
                        })
                    }
                    doc['document_type'] = doc.type;
                })
                return filteredDocs;
            } else {
                docs.forEach((doc) => {
                    doc['document_type'] = doc.type;
                })
                return docs;
            }
           
        },
        document_tree() {
            let docs = this.documents != null ? this.processTree(this.documents) : [];
            console.log(docs);
            // //check for document Notifications
            // this.new_notifications.forEach(item => {
            //    let data = item.data
            //    console.log(data.type)
            //    if(data.type == "document_assignment") {
            //         docs.forEach(doc => {
            //             if(doc.id == data.document_id) {
            //                 doc['to_accept'] = true
            //             }
            //         })
            //    }
            // })

            if (this.selected_user_id) {
                let filtered_list = [];
                docs.forEach(item => {
                    //! fixed filtering
                    //if(item.owner_user_id == Number(this.selected_user_id)){
                    //    filtered_list.push(item);
                    //}
                    if (item.is_owner || item.is_co_author) {
                        filtered_list.push(item);
                    }
                    else {
                        item.assignments.forEach(assignment => {
                            if (assignment.user_id == Number(this.selected_user_id)) {
                                filtered_list.push(item);
                            }
                        })
                    }
                });
                let list =  _.uniqBy(filtered_list, 'id');
                return list
                //* filter for user is
                //console.log(docs)
                //return _.filter(docs, { owner_user_id : Number(this.selected_user_id)});
            }
            else {
                //* return all
                return docs;
            }
        },
        hasDocuments() {
            let valid = false;
            if (this.documents != null) {
                valid = true;
            }
            return valid;
        },
        hasNoDocuments() {
            let valid = false;
            if (this.documents != null) {
                if (this.documents.length == 0) {
                    valid = true;
                }
            }
            return valid;
        },
        variable_watchers() {
            var el = this;
            return {
                //eslint-disable-next-line
                level_ids(newVal, oldVal, id, is_new_row) {
                    if (is_new_row) {
                        return;
                    }
                    //* check if owner can access the document
                    let item = window.webix.$$(el.table_uid).getItem(id);
                    let owner = el.all_users.find(user => { user.id == item.owner_user_id });
                    console.log('OWNER OBJECT', owner);
                },
            }
        },
        simple_columns(){
            // eslint-disable-next-line
            let el = this;
            let data = [
                {
                    id: "#",
                    sort: "int",
                    header: "#", width: 40,
                    searchable: true,
                    //eslint-disable-next-line
                    template: (obj) => {
                        if(obj) {
                            let value = window.webix.$$(el.table_uid).data.getIndexById(obj.id);
                            return value + 1
                        } else {
                            return ''
                        }
                    }
                },
                {
                    id: "title",
                    sort: "string",
                    header: el.canShowHeaderFilters ? ["Document Title", { content: "textFilter" }] : ["Document Title"], tooltip: el.test, minWidth: 400,
                    fillspace: true, editor: 'text', searchable: true,
                    //eslint-disable-next-line
                    template: (obj, common, value) => {
                        if(value){
                            if(el.table_view_mode == 'view') {
                                return `<span class="view_action cursor-pointer">${value}</span>`
                            } else {
                                return `<span>${value}</span>`
                            } 
                        }
                        else {
                            return '';
                        }
                    }
                },
                { id: "document_type", header: el.canShowHeaderFilters ? ["Type", { content: "richSelectFilter" }] : ["Type"], editor: 'combo', options: el.type_options, width: 100, searchable: true, 
                },
                { id: "published_version_no", header: "Version No", width: 100, css: 'center', 
                    // template: (obj) => {
                    //     if(obj.to_accept) {
                    //        return "TEST" + obj.published_version_no
                    //     } else {
                    //        return obj.published_version_no
                    //     }
                    // }
                },
                {
                    id: "issue_date", header: "Issue Date", visibility: ['view', 'delete'], sort: "string", editor: "date", width: 120, suggest: { type: "calendar" },
                    format: function (value) {
                        let format = window.webix.Date.dateToStr("%d-%M-%Y")
                        if (value === 'N/A') {
                            return value;
                        }
                        return format(value);
                    },
                },
                {
                    id: "owner_user_id", header: el.canShowHeaderFilters ? ["Owner", { content: "richSelectFilter" }] : ["Owner"], editor: "combo", options: this.userList, searchable: true, sort: 'text',
                    width: 120,
                    suggest: {
                        view: "suggest",
                        filter: function (item, value) {
                            let text = value.toLowerCase();
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if (name.includes(text) || email.includes(text)) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        },
                        body: {
                            view: "list",
                            data: this.userList,
                            template: "#value# - #email#",
                            yCount: 10
                        },
                    },
                    template: function (obj) {
                        if (obj.is_empty) {
                            return '';
                        }
                        let archivedCheck = el.all_users.find(e => { 
                            return e.id == Number(obj.owner_user_id)
                        })
                        if(archivedCheck) {
                            //check for Archived User
                            return archivedCheck.name
                        } else {
                            return 'Archived User'
                        }
                        
                    }
                },
                { id: "status", header: "Status", sort: 'text', width: 100},
                {
                    id: "acceptance_rate", header: "Acceptance", sort: 'int', width: 100, css: 'center',
                    //Changed to format to not replace the value.
                    format(value) {
                        return value + '%'
                    }
                },
                { id: "assigned_users_count", header: "Assigned Users", sort: 'text', width: 110, css: 'center' },
                {
                    id: "review_date", header: "Review Date", sort: "string", editor: "date", width: 100, suggest: { type: "calendar" },
                    format: function (value) {
                        let format = window.webix.Date.dateToStr("%d-%M-%Y")
                        
                        if (value == '') {
                            return 'N/A';
                        }
                        return format(value);
                    },
                },
                {
                    id: "actions", header: 'Action', width: 70, tooltip: false,  css: 'center',
                    template(obj) {
                        if (obj.is_empty || obj.is_new) {
                            return '';
                        }
                        else {
                            let inner_html = '';
                            if (obj.is_owner || obj.is_co_author) {
                                inner_html = `<span class="action_text edit_document_action me-2"><i class="me-1 fas fa-edit"></i></span>`
                            } else {
                                inner_html = `<span class="action_text view_action me-2"><i class="me-1 fas fa-book"></i></span>`
                            }
                            return `<span class="d-flex action-buttons-flex" style="justify-content: center">${inner_html}</span>`
                        }
                    }
                }
            ]
            return data;
        },  
        columns() {
            // eslint-disable-next-line
            let el = this;
            let data = [
                // { id: "published_revision_no", header: "Revision No", },
                {
                    id: "title",
                    sort: "string",
                    header: el.canShowHeaderFilters ? ["Document Title", { content: "textFilter" }] : ["Document Title"], minWidth: 400, tooltip: true,
                    fillspace: true, editor: 'text', exportAsTree: true, searchable: true,
                    //eslint-disable-next-line
                    template: (obj, common, value) => {
                        if(value != ''){
                            if(el.table_view_mode == 'view') {
                                return `<span class="view_action cursor-pointer">${value}</span>`
                            } else {
                                return `<span>${value}</span>`
                            } 
                        }
                        else {
                            return '';
                        }
                    }
                },
                { id: "document_type", header: el.canShowHeaderFilters ? ["Document Type", { content: "richSelectFilter" }] : ["Document Type"], editor: 'combo', options: el.type_options, width:100, searchable: true, },
                // {
                //     id: "issue_date", header: "Issue Date", visibility: ['view', 'delete'], sort: "string", editor: "date", width: 100, suggest: { type: "calendar" },
                //     format: function (value) {
                //         let format = window.webix.Date.dateToStr("%d-%M-%Y")
                //         if (value === 'N/A') {
                //             return value;
                //         }
                //         return format(value);
                //     },
                // },
                {
                    id: "owner_user_id", header: el.canShowHeaderFilters ? ["Owner", { content: "richSelectFilter" }] : ["Owner"], editor: "combo", options: this.userList, searchable: true, sort: 'text',
                    width: 120,
                    suggest: {
                        view: "suggest",
                        filter: function (item, value) {
                            let text = value.toLowerCase();
                            console.log(text)
                            let name = item.value.toLowerCase();
                            let email = item.email.toLowerCase();
                            if (name.includes(text) || email.includes(text)) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        },
                        body: {
                            view: "list",
                            data: this.userList,
                            template: "#value# - #email#",
                            yCount: 10
                        }
                    },
                    template: function (obj) {
                        console.log(obj)
                        if (obj.is_empty) {
                            return '';
                        }
                        let archivedCheck = el.all_users.find(e => { 
                            return e.id == Number(obj.owner_user_id)
                        })
                        if(archivedCheck) {
                            //check for Archived User
                            return archivedCheck.name
                        } else {
                            return 'Archived User'
                        }
                    }
                },
                //{ id: 'level_id', fillspace: 0.5, header: 'Level', editor: 'combo', options: el.availableLevels},
                // {
                //     id: "level_ids",
                //     //editor: "combo",
                //     header: "Organisational Structure",
                //     width: 350,
                //     template: function (obj) {
                //         if (obj.level_ids != undefined) {
                //             //let index = _.findIndex(el.levels, {id: obj.level_id });
                //             //let level_name = '';
                //             //if(index != -1){
                //             //    level_name = el.levels[index].name;
                //             //}
                //             if (el.table_view_mode === 'edit') {
                //                 //* we only need to show the pencil icon in edit mode
                //                 return `<span class="show_hierarchy_text"><i class="me-1 show_hierarchy_modal fas fa-pen"></i>${el.getLevelNamesForIds(obj.level_ids)}</span>`
                //             }
                //             else {
                //                 return `<span>${el.getLevelNamesForIds(obj.level_ids)}</span>`
                //             }
                //         }
                //         else {
                //             return ''
                //         }
                //     },
                // },
                {
                    id: "review_date", header: "Review Date", sort: "string", editor: "date", width: 120, suggest: { type: "calendar" },
                    format: function (value) {
                        let format = window.webix.Date.dateToStr("%d-%M-%Y")
                        if (value === 'N/A') {
                            return value;
                        }
                        return format(value);
                    },
                },
                {
                    id: "actions", header: 'Action', width: 70, tooltip: false,  css: 'center', editor: 'text',
                    template(obj) {
                        if (obj.is_empty || obj.is_new) {
                            return '';
                        }
                        else {
                            let inner_html = '';
                            if (obj.is_owner || obj.is_co_author) {
                                inner_html = `<span class="action_text edit_document_action me-2"><i class="me-1 fas fa-edit"></i></span>`
                            } else {
                                inner_html = `<span class="action_text view_action me-2"><i class="me-1 fas fa-book"></i></span>`
                            }
                            return `<span class="d-flex action-buttons-flex" style="justify-content: center">${inner_html}</span>`
                        }
                    }
                }
            ];
            return data;
        },
        button_methods() {
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'edit_action': function (e, id) {

                    console.log(id)
                    let item = this.getItem(id)
                    console.log(item)
                    //if (el.$refs[el.table_uid].table_mode == 'view') {
                        //el.$emit('selectedDocument', { id: id, read_only: false });
                    //}
                },
                'edit_document_action': function (e, id) {
                    el.$emit('selectedDocument', { id: id, read_only: false });
                },
                'view_action': function (e, id) {
                    el.$emit('selectedDocument', { id: id, read_only: true });
                },
                'show_hierarchy_modal': function (e, id) {
                    let item = this.getItem(id);
                    let is_owner = el.core.id != item.owner_user_id;
                    let is_power_user = el.$hasUserRole('Power User');
                    if (is_owner || is_power_user) {
                        el.showHierarchyModal(item);
                    }
                    else {
                        el.$swal.fire('Permission to edit is unavailable');
                        return;
                    }

                },
            }
            return onClick;
        },
        default_values() {
            let el = this;
            return [
                { id: 'level_ids', value: [el.selected_level.id] },
                { id: 'parent_id', value: 0 },
                { id: 'owner_user_id', value: el.core.id }
            ]
        },
        table_validation_rules() {
            return {
                "title": function (value, object) {
                    if (object.is_empty) {
                        return true;
                    }
                    else {
                        if (value === '' || value == undefined) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                },
                "document_type": function (value, object) {
                    if (object.is_empty) {
                        return true;
                    }
                    else {
                        if (value === '' || value == undefined) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                },
                "owner_user_id": function (value, object) {
                    if (object.is_empty) {
                        return true;
                    }
                    else {
                        if (value === '' || value == undefined) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                },
                "level_ids": function (value, object) {
                    if (object.is_empty) {
                        return true;
                    }
                    else {
                        if (value == [] || value == undefined) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                },
                // "review_date": function (value, object) {
                //     if (object.is_empty) {
                //         return true;
                //     }
                //     else {
                //         if (value === [] || value == undefined) {
                //             return false;
                //         }
                //         else {
                //             return true;
                //         }
                //     }
                // }
            }
        },
        userList() {
            let users = [];
            _.forEach(this.all_users, function (value) {
                users.push({ id: value.id, value: value.name, email: value.email });
            });
            return users;
        },
        availableLevels() {
            return this.levels.map(level => {
                return { id: level.id, value: level.name }
            });
        },
        parentDocumentsList() {
            let list = this.document_list.map(item => {
                return { id: item.id, value: item.title };
            });
            list.unshift({ id: '0', value: 'No Parent' });
            return list;
        },
        
    },
    methods: {
        ...handbookMethods,
        ...profileMethods,
        triggerViewDocument(doc) {
            this.$swal.fire({
                title: "View Document",
                text: "Would you like to view document " + '"' + doc.title + '"',
                icon: "warning",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Ok",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
            }).then( result =>{
                if(result.value){
                    this.$emit('selectedDocument', { id: doc.id, read_only: true });
                }
            })           
        },
        triggerEditDocument(doc) {
            this.$emit('selectedDocument', { id: doc.id, read_only: false });       
        },
        removeTooltip() {
           window.webix.AutoTooltip.$tooltipOut()
        },
        addDocumentsModal(){
            console.log("Running here");
            this.modal_payload = {};
            this.show_add_document_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('addDocumentModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "addDocuments") {
                        this.show_add_document_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
        isUserOwnerOfDocument(user_id) {
            let co_author_index = this.document.co_author_users.findIndex(user => {
                return Number(user.id) == Number(user_id);
            });
            let is_co_author = co_author_index != -1;
            let is_owner = Number(user_id) == Number(this.document.owner_user_id);
            return is_owner || is_co_author;
        },
        getLevelNamesForIds(id_array) {
            let level_names = [];
            let names_string = '';
            id_array.forEach(id => {
                let level = _.find(this.levels, { id: id });
                level_names.push(level.name);
            })
            level_names = level_names.sort();
            level_names.forEach((name, idx) => {
                if (idx == 0) {
                    names_string += name;
                }
                else {
                    names_string += `, ${name}`
                }
            });
            return names_string
        },
        getDocumentList() {
            let docs = [];
            if (this.search === '') {
                return this.documents;
            }
            else {
                for (let index = 0; index < this.documents.length; index++) {
                    if (this.documents[index].title.toLowerCase().includes(this.search.toLowerCase())) {
                        docs.push(this.documents[index])
                    }
                    else continue;
                }
                return docs;
            }
        },
        loadDocumentsForLevel(show_spinner = false) {
            return new Promise((resolve) => {
                this.selected_row = -1;
                this.is_loading = true;
                if (show_spinner) {
                    this.showLoadingSpinner(true);
                }
                this.loadDocuments(this.selected_level.id)
                    .then(() => {
                        this.is_loading = false;
                        this.showLoadingSpinner(false);
                        resolve();
                    })
            })
        },
        getParentsAndChildren(id) {
            let children = [];
            this.documents.forEach(document => {
                if (id == document.parent_id) {
                    children.push(document)
                }
            })
            this.child_documents = children;
            /* -- -- -- */
            let parent = [];
            let current_index = _.findIndex(this.documents, { id: this.selected_id });
            if (current_index != -1) {
                let parent_id = this.documents[current_index].parent_id;
                let parent_index = _.findIndex(this.documents, { id: parent_id });
                if (parent_index != -1) {
                    parent.push(this.documents[parent_index]);
                }
            }
            this.parent_documents = parent;
        },
        clearParentAndChildDocuments() {
            this.child_documents = [];
            this.parent_documents = [];
        },
        saveTable(data) {
            let rows_to_save = [];
            data.forEach(item => {
                let params = {
                    id: item.is_new ? -1 : item.id,
                    level_ids: item.level_ids,
                    parent_id: item.parent_id == item.id ? 0 : item.parent_id,
                    title: item.title,
                    type: item.document_type,
                    owner_user_id: Number(item.owner_user_id),
                    review_date: item.review_date
                };
                if (params.parent_id == null) {
                    params.parent_id = 0;
                }
                rows_to_save.push(params);
            });

            let save_params = {
                documents: rows_to_save
            }
            this.saveDocument(save_params).then(() => {
                this.loadDocumentsForLevel()
                    .then(() => {
                        this.$refs[this.table_uid].table_mode = 'view'
                    })
            })

        },
        deleteRow(id_array) {
            this.is_loading = true;
            let promises = [];
            id_array.forEach(item => {
                promises.push(
                    this.deleteProcessDocument(item.id)
                );
            })
            Promise.all(promises)
                .then(() => {
                    this.loadDocumentsForLevel()
                        .then(() => {
                            this.is_loading = false;
                            this.$refs[this.table_uid].table_mode = 'view';
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Success!',
                                toast: true,
                                position: 'top',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            })
                        })
                })
                .catch(() => {
                    this.$swal.fire('Deleting one or more items failed');
                    this.loadDocumentsForLevel()
                        .then(() => {
                            this.is_loading = false;
                        })
                })
        },
        doubleClickEvent(id) {
            this.$emit('selectedDocument', { id: id, read_only: true });
        },
        selectedRowEvent(row) {
            if (!row) {
                this.selected_id = -1;
                this.clearParentAndChildDocuments();
                return;
            }
            this.selected_id = row.id;
            this.getParentsAndChildren(this.selected_id);

            //this.$emit('selectedDocument', {id: row.id, read_only: true});
        },
        documentCountEvent(count) {
            this.documentCount = count
        },
        processTree(data_array) {
            var data = _.cloneDeep(data_array);
            data.forEach((ele) => {
                delete ele.children;
                ele['document_type'] = ele.type,
                    ele['data'] = [];
                let parent_id = ele.parent_id;
                if (parent_id == 0) {
                    ele.parent_id = null;
                    //* Parent item -- do nothing
                    //filter_array.push(parent_id)
                }
                else {
                    //* If element is a child element, push to children (data) array.
                    data.forEach((d) => {
                        if (d.id === parent_id) {
                            //* flagged items will be ignored - prevent duplicate items causing the treetable to act as a datatable...
                            ele['flagged'] = true;

                            let childArray = d.data;
                            if (!childArray) {
                                childArray = [];
                            }
                            childArray.push(ele);
                            d.data = childArray;
                        }
                        else {
                            //* hmm
                            //TODO - verify this fixed duplicates not being removed
                            let index = _.findIndex(data, { id: d.parent_id });
                            if (index == -1) {
                                d.parent_id = null;
                            }
                            //d.data = undefined
                        }
                    });
                }
            });
            //* Remove duplicate elements
            data = data.filter((ele) => ele.data != undefined || ele.parent_id == null);
            //* fix for activity-other objectives not showing children as a tree
            data = data.filter((ele) => ele.flagged == undefined);
            
            return data;
        },
        //Linking modals
        showHierarchyModal(payload) {
            this.modal_payload = payload;
            console.log(JSON.parse(JSON.stringify(this.modal_payload)));
            this.show_hierarchy_modal = true;
            this.$nextTick(() => {
                this.$bvModal.show('levelLinkingModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "levelLinkingModal") {
                        this.show_hierarchy_modal = false;
                    }
                });
            })
        },
        //eslint-disable-next-line
        handleRowLevelsUpdate(payload) {
            /*
            let item = window.webix.$$(el.table_uid).getItem(id);
            let owner = el.all_users.find( user => { user.id == item.owner_user_id});
            console.log('OWNER OBJECT',owner);
            */
            let updated_row = this.modal_payload;
            let owner = this.all_users.find(user => user.id == updated_row.owner_user_id);
            if (owner) {
                let user_levels = owner.user_levels.map(lvl => { return lvl.id });
                let has_all_structures_perm = owner.user_permissions.find(perm => perm.slug == 'all-structures') != undefined ? true : false;
                let has_level_access = payload.some(value => user_levels.includes(value));
                if (has_all_structures_perm == false && has_level_access == false) {
                    this.$swal.fire('The current document owner does not have access to the newly assigned structure')
                }
            }
            updated_row.level_ids = payload;
            let table = window.webix.$$(this.table_uid);
            table.updateItem(updated_row.id, updated_row);
            this.$bvModal.hide('levelLinkingModal');
        },
    },
    mounted() {
        window.addEventListener('wheel', this.removeTooltip);  
        if (this.selected_level != null && this.documents == null) {
            this.loadDocumentsForLevel(true);
        }
        if(this.selected_level.id != this.last_used_level_id) {
            this.loadDocumentsForLevel(true);
        }
        if(this.company_id) {
            this.loadCompanyDocuments(this.company_id)
        }
        if(this.selected_level.id) {
            this.manuallySetLastUsedLevelID(this.current_level_id)
        }
        this.$nextTick(() => {
            this.documentCount = this.document_tree.length
        })
    },
}
</script>

<style>

    .action_text:hover{
        cursor: pointer;
        font-weight: bold;
        text-decoration: underline;
    }
    .action-buttons-flex{
        justify-content:flex-start;
    }
    .edit_action{
        color: var(--bs-primary);
    }
    /* .edit_action{
        color: var(--bs-primary);
    } */
    .center{
        text-align: center;
    }
    .document-main {
        padding-bottom: 60px
    }
    /* .view_action{ */
        /* color: var(--bs-info); */
    /* } */

</style>